import React, { useState } from 'react';
import { Button, Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import FormTextField from './FormTextField';
import { enviarFormulario } from './formService';

const initialFormData = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
  curriculum: null,
  acceptTerms: false,
};

const ContactForm = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      curriculum: file,
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      acceptTerms: e.target.checked,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'El nombre es obligatorio';
    if (!formData.lastname.trim()) newErrors.lastname = 'El apellido es obligatorio';
    if (!formData.phone.trim() && !formData.email.trim()) {
      newErrors.phone = 'Debe ingresar al menos un teléfono o un correo';
      newErrors.email = 'Debe ingresar al menos un teléfono o un correo';
    }
    if (!formData.acceptTerms) {
      newErrors.acceptTerms = 'Debe aceptar los términos y condiciones';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null) {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = enviarFormulario(formDataToSend);

      if (response) {
        alert('Formulario enviado exitosamente');
        setFormData(initialFormData);
      } else {
        alert('Hubo un error al enviar el formulario');
      }
    } catch (error) {
      alert('Error al conectar con la plataforma Make');
    }
  };

  return (
    <Box sx={{ padding: '2em', backgroundColor: 'secondary.main', borderRadius: 2, alignContent: 'center' }}>
      <Typography
        variant="h3"
        color="white"
        sx={{ fontWeight: 'bold', textAlign: 'left', paddingLeft: 2, marginBottom: 2 }}
      >
        Conecta con nosotros
      </Typography>
      <Typography
        variant="h3"
        color="white"
        sx={{ fontWeight: 'bold', textAlign: 'left', paddingLeft: 2, marginBottom: 2 }}
      >
        y descubre más.
      </Typography>

      <form onSubmit={handleSubmit}>
        <FormTextField
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <FormTextField
          label="Apellido"
          name="lastname"
          value={formData.lastname}
          onChange={handleChange}
          error={!!errors.lastname}
          helperText={errors.lastname}
        />
        <FormTextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          type="email"
        />
        <FormTextField
          label="Teléfono"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          error={!!errors.phone}
          helperText={errors.phone}
          type="tel"
        />
        <FormTextField
          label="Mensaje"
          name="message"
          value={formData.message}
          onChange={handleChange}
          multiline
          minRows={4}
        />

        <Typography variant="body1" color="white" sx={{ mt: 2, mb: 1 }}>
          Subir currículum (opcional):
        </Typography>
        <Button variant="outlined" component="label" fullWidth>
          Subir archivo
          <input type="file" hidden onChange={handleFileChange} />
        </Button>

        <FormControlLabel
          control={<Checkbox checked={formData.acceptTerms} onChange={handleCheckboxChange} />}
          label="Acepto los términos y condiciones"
        />
        {errors.acceptTerms && (
          <Typography variant="caption" color="error">
            {errors.acceptTerms}
          </Typography>
        )}

        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Enviar
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
